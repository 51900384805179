import { markRaw } from 'vue'
import RootComponent from 'src/App.vue'
import createRouter from 'src/router/index'

export default async function (createAppFn) {
  // Create the app instance.
  // Here we inject into it the Quasar UI, the router & possibly the store.
  const app = createAppFn(RootComponent)

  app.config.performance = true

  const router = markRaw(typeof createRouter === 'function' ? await createRouter({}) : createRouter)

  // Expose the app, the router and the store.
  // Note that we are not mounting the app here, since bootstrapping will be
  // different depending on whether we are in a browser or on the server.
  return {
    app,

    router
  }
}
