export default {
  label: {
    avatar: 'Avatar',
    nickname: 'Nickname',
    mobile: 'Mobile Phone Number',
    email: 'Email',
    bankcard: 'Bank Account',
    password: 'Login Password',
    fundPassword: 'Security Password',
    changeAvatar: 'Change Now',
    selectAvatar: 'Select your avatar',
    setPassword: 'Set Password',
    currentPassword: 'Current Password',
    newPassword: 'New password',
    passwordAgain: 'New Password again',
    changePassword: 'Change Password',
    selectLanguage: 'Select Language',
    language: 'Language',
    forgotPassword: 'Forgot Password',
    contactPhone: 'Contact mobile phone number',
    contactEmail: 'Contact email address',
    account: 'Account Settings',
    streamRecords: 'Account Balance Records',
    rechargeRecords: 'Recharge Records',
    withdrawRecords: 'Withdraw Records',
    inviteFriends: 'Invite Friends',
    aboutUs: 'About Us',
    appDownload: 'App Download',
    pixWallet: 'PIX wallet',
    pixAcctType: 'PIX account type',
    pixAcctName: 'PIX account name',
    pixAcctNo: 'PIX account number',
    cpfCertId: 'CPF',
    dailySignIn: 'Daily SignIn',
  },
  message: {
    avatarChanged: 'Avatar changed',
    fundPasswordUpdated: 'Security password updated',
    passwordChanged: 'Password changed',
    bankcardUpdated: 'Bank account updated',
    pixWalletUpdated: 'PIX Wallet Account Updated',
  },
  placeholder: {
    currentPassword: 'Current password',
    newPassword: 'New password',
    passwordAgain: 'New Password again',
    contactPhone: 'Enter Mobile Phone Number',
    contactEmail: 'Contact email address',
    pixAcctType: 'Select PIX Account Type',
    pixAcctName: 'Enter PIX account name',
    pixAcctNo: 'Enter PIX account number',
    cpfCertId: 'Enter the beneficiary tax code',
  },
  hint: {
    currentPassword: 'Current login password',
    newPassword: 'Set new password',
    confirmPassword: 'Confirm new password',
  },
  tips: {
    forgotPassword: 'In case you forget current login password, goto {0}.',
  },
};
