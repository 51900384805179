import { createVNode } from 'vue';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { logErrorMessages } from '@vue/apollo-util';
import { useAuthStore } from 'src/store/authStore';
import { useUserStore } from 'src/store/userStore';
import { useI18n } from 'src/boot/i18n';
import router from 'src/router';
import { Modal, notification } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { gotoRecharge } from './utils';
import EventBus from './layouts/EventBus';
import { GE_SHOW_LOGIN, GE_SHOW_PIXWALET, GE_GQL_SERVER_ERROR } from './layouts/EventBus';

const { t } = useI18n();

const errorLink = onError((error) => {
  const { networkError, graphQLErrors } = error;

  if (networkError) {
    switch (networkError.statusCode) {
      case 401:
      case 403:
        EventBus.emit(GE_SHOW_LOGIN, { goHome: true });
        break;

      case 503:
      case 504:
        router.replace({ name: 'ErrorNetwork' });
        break;
    }
  }

  if (graphQLErrors?.detail && graphQLErrors.detail === 'Internal Server Error') {
    EventBus.emit(GE_GQL_SERVER_ERROR);
  }

  if (graphQLErrors?.length > 0) {
    let message = graphQLErrors[0].message;
    const params = graphQLErrors[0].params || {};
    EventBus.emit(GE_GQL_SERVER_ERROR);
    console.log('message', message, graphQLErrors[0].params);

    if (message === 'not_enough_balance') {
      Modal.confirm({
        title: t('common.hint'),
        icon: createVNode(ExclamationCircleOutlined),
        content: t('error.hint.not_enough_balance'),
        okText: t('common.go'),
        cancelText: 'Cancel',
        onOk: () => {
          gotoRecharge();
        },
      });
    } else if (message === 'pixWalletNotSet') {
      const userStore = useUserStore();
      userStore.setPixWallet({});

      Modal.confirm({
        title: t('withdraw.label.bindPixWallet'),
        icon: createVNode(ExclamationCircleOutlined),
        content: t('withdraw.message.bindPixWallet'),
        okText: t('common.go'),
        cancelText: t('common.cancel'),
        onOk: () => {
          EventBus.emit(GE_SHOW_PIXWALET);
        },
      });
    } else {
      let i18nMessage = t(`server.${message}`, params);
      if (!i18nMessage.startsWith('server.')) {
        message = i18nMessage;
      } else {
        i18nMessage = t(`error.server.${message}`, params);

        if (!i18nMessage.startsWith('error.server.')) {
          message = i18nMessage;
        }
      }

      notification.error({
        message: '',
        description: message,
        placement: 'top',
        style: 'color: red',
      });
    }
  }

  logErrorMessages(error);
});

const authLink = setContext((_, { headers }) => {
  const authStore = useAuthStore();
  const token = authStore.accessToken;

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    },
  };
});

const customFetch = async (uri, options) => {
  const initialResponse = await fetch(uri, options);

  if (initialResponse.ok || initialResponse.status !== 403) {
    return initialResponse;
  }

  const authStore = useAuthStore();
  const newAccessToken = await authStore.refreshAccessToken();
  options.headers.authorization = `Bearer ${newAccessToken}`;

  const response = await fetch(uri, options);
  return response;
};

// HTTP connection to the API
const httpLink = createHttpLink({
  uri: '/gql',
  fetch: customFetch,
});

const mergeListResult = (existing, incoming) => {
  if (!existing) return incoming;

  if (existing.items.find(({ __ref }) => __ref === incoming.items[0].__ref)) {
    return existing;
  } else {
    return {
      ...incoming,
      items: [...existing.items, ...incoming.items],
    };
  }
};

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        listSiteMessages: {
          // keyArgs: ["paginationParams"],
          merge: mergeListResult,
        },
        listStreamRecords: {
          merge: mergeListResult,
        },
        listBetRecords: {
          merge: mergeListResult,
        },
        listRechargeOrders: {
          merge: mergeListResult,
        },
        listWithdrawOrders: {
          merge: mergeListResult,
        },
        listUserTeam: {
          merge: mergeListResult,
        },
      },
    },
  },
});

// const cache = new InMemoryCache();

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache,
});
