export default {
  title: {
    promotion: 'Promoção',
    illustration: 'Ilustração',
    downloadIos: 'Visite-nos no seu iPhone'
  },
  label: {
    inviteDescription: 'Este site oferece jogos com uma experiência de aventura.',
    onlineCustomerService: 'Atendimento ao cliente online',
    helpCenter: 'Centro de ajuda',
    allGames: 'Tudo'
  },
  message: {}
}
