export default {
  shareLink: 'Link de compartilhamento',
  shareYourLink: 'Compartilhe seu link',
  referralLink: 'Link de indicação',
  referralCode: 'Código de indicação',
  qrCode: 'Código QR',
  todayInviteData: 'Os dados do convite de hoje',
  inviteRewards: 'Recompensas de convite',
  earnRewards: 'Ganhe recompensas',
  totalIncome: 'Renda total',
  totalInvitedUsers: 'Total de convidados',
  totalPromotionBonus: 'Recompensas totais de convite',
  totalCommission: 'Comissão total',
  containLevel123: '(Contém Nível1&2&3)',
  promotionBonus: 'Bônus de convite',
  dbyCommission: 'Recompensa da Comissão anteontem',
  yesterdayCommission: 'Comissão de ontem',
  teamCommission: 'Comissão da equipe',
  dbyVipRebate: 'Desconto VIP anteontem',
  dbyWithdrawPoint: 'Volume de codificação de anteontem',
  yesterdayVipRebate: 'Desconto VIP de ontem',
  yesterdayWithdrawPoint: 'Volume de codificação de ontem',
  vipRebateBonus: 'Bônus de desconto',
  congratulationOnWinning: 'Parabéns por ganhar',
  effectiveInvites: 'Número de depositantes',
  claimedInvites: 'Recebidos',
  availableInvites: 'Nao consta',
  unavailableInvites: 'Pagar só amanhã',
  yesterdayLossBenefit: 'Perda de benefícios ontem',
  lossBenefit: 'Alívio de danos',
  cumulativeAmount: 'Montante cumulativo',
  numberOfPeople: 'Número de pessoas',
  rewardHint:
    'O bônus será creditado na conta no dia seguinte. Certifique-se de reivindicá-lo dentro de 48 horas. Ele será inválido se expirar.',
};
