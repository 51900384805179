import { apolloClient } from 'src/apolloClient'
import { Query } from 'src/graphql'

export async function postJson(uri, params) {
  const response = await fetch(uri, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  })

  if (!response.ok) {
    throw response
  }

  const json = await response.json()

  return json
}

export async function login(countryCode, accountName, password) {
  const result = await postJson('/api/login', {
    countryCode,
    accountName,
    password
  })
  return result
}

export async function refreshAccessToken(refreshToken) {
  const result = await postJson('/api/refresh_token', { refreshToken })
  return result
}

export async function sendIdentifyCode(countryCode, mobile, email) {
  const result = await postJson('/api/send_identify_code', {
    countryCode,
    mobile,
    email
  })
  return result
}

export async function isMobileExists(countryCode, mobile) {
  const result = await postJson('/api/is_mobile_exists', { countryCode, mobile })
  return result
}

export async function isEmailExists(email) {
  const result = await postJson('/api/is_email_exists', { email })
  return result
}

export async function isNameExist(name) {
  const result = await postJson('/api/is_name_exists', { name })
  return result
}

export async function registerUser(params) {
  const result = await postJson('/api/register_user', params)
  return result
}

export async function resetPassword(params) {
  const result = await postJson('/api/reset_password', params)
  return result
}

export async function isTransactionIdExists(txid) {
  try {
    const {
      data: { isTransactionIdExists }
    } = await apolloClient.query({
      query: Query.isTransactionIdExists,
      variables: { txid },
      fetchPolicy: 'network-only'
    })

    return isTransactionIdExists
  } catch (e) {
    return false
  }
}

export async function isPixCertIdExists(certId) {
  try {
    const {
      data: { isPixCertIdExists }
    } = await apolloClient.query({
      query: Query.isPixCertIdExists,
      variables: { certId },
      fetchPolicy: 'network-only'
    })

    return isPixCertIdExists
  } catch (e) {
    return false
  }
}
