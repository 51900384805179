import { defineStore } from 'pinia';
import { addServerMessages } from 'src/boot/i18n';
import { getOS } from 'src/utils';

// main is the name of the store. It is unique across your application
// and will appear in devtools
export const useConfigStore = defineStore('config', {
  state: () => ({
    countryCode: '55',
    carouselItems: [],
    cooperationSites: [],
    sysConfig: {
      userAccountType: 'mobile',
      registerRequireIdentifyCode: false,
    },
    sysWallet: {},
    sysBankcard: {},
    siteNotices: [],
    readNotices: [],
    customerServices: [],
    specialPromotionConfig: { active: false },
    faqs: [],
    categories: [],
    gameTabs: [],
    vipPrivileges: [],
    payChannels: [],
    providers: [],
    inviteCode: null,
    prevRoute: null,
    securityNext: null,
    maxVipLevel: 10,
    platform: 'pc',
    showDownloadGuideCounter: 0,
    downloaded: false,
    treasurePromotionActivities: [],
    treasurePromotionActivityIds: [],
    hintDownload: true,
  }),
  // optional getters
  getters: {
    nextRouteAfterSecurityGuide() {
      if (this.securityNext) {
        return this.securityNext;
      } else {
        return { name: 'WithdrawUsdt' };
      }
    },
  },
  // optional actions
  actions: {
    async init() {
      let matches = /[?&]invite=([^&#]*)/i.exec(location.href);
      this.inviteCode = matches && matches.length == 2 ? matches[1] : null;

      matches = /[?&]hintDownload=([^&#]*)/i.exec(location.href);
      this.hintDownload = matches && matches.length == 2 && matches[1] === 'false' ? false : true;

      this.platform = getOS();

      this.showDownloadGuideCounter = localStorage.getItem('showDownloadGuideCounter') || 0;

      console.log('showDownloadGuideCounter', this.showDownloadGuideCounter);

      this.downloaded = localStorage.getItem('downloaded') || false;

      await this.fetch();
      await this.fetchVipPrivileges();
    },

    incShowDownloadGuideCounter() {
      this.showDownloadGuideCounter += 1;
      localStorage.setItem('showDownloadGuideCounter', this.showDownloadGuideCounter);
    },

    setDownloaded() {
      this.downloaded = true;
      localStorage.setItem('downloaded', true);
    },

    getUnreadNotice() {
      const notice = this.siteNotices.find((n) => this.readNotices.indexOf(n.id) === -1);

      return notice;
    },

    setNoticeRead(id) {
      this.readNotices.push(id);
    },

    setPrevRoute(prev) {
      if (prev.name !== 'ErrorNetwork') {
        this.prevRoute = prev;
      }
    },

    setSecurityNext(next) {
      this.securityNext = next;
    },

    async fetch() {
      let response = await fetch('/api/get_config_url');
      if (!response.ok) {
        throw response;
      }

      const { url, countryCode } = await response.json();

      response = await fetch(url);
      if (!response.ok) {
        throw response;
      }

      const config = await response.json();

      const treasurePromotionActivityIds =
        config.treasurePromotionActivities?.map((item) => item.id) || [];

      this.$patch({ ...config, countryCode, treasurePromotionActivityIds });

      addServerMessages(config.i18nMessages);
    },

    async fetchVipPrivileges() {
      const response = await fetch('/api/list_vip_privileges');

      if (!response.ok) {
        throw response;
      }

      const { vipPrivileges } = await response.json();

      this.$patch({ vipPrivileges });
    },
  },
});
