// This is just an example,
// so you can safely delete all default props below

export default {
  nav: {
    home: 'Game',
    promotion: 'Promotion',
    records: 'Record',
    activity: 'Activity',
    userCenter: 'Mine',
  },
  title: {
    Login: 'User Login',
    Activity: 'Activity',
    Register: 'User register',
    ForgotPassword: 'Forgot Password',
    Home: 'Home',
    UserCenter: 'User Center',
    Invite: 'Invite Friends',
    Settings: 'Settings',
    Avatar: 'Select Avatar',
    Password: 'Change Password',
    FundPassword: 'Security Password',
    Bankcard: 'Bank Account',
    Messages: 'Messages',
    RechargeRecords: 'Deposit History',
    WithdrawRecords: 'Withdrawal History',
    StreamRecords: 'Account Balance Records',
    Organization: 'About Us',
    TermsOfService: 'Terms Of Service',
    BusinessModel: 'Product Description',
    CompanyProfile: 'Company Profile',
    FaqDetail: 'FAQ',
    PixWallet: 'PIX Wallet',
    Team: 'My Team',
    TeamDetail: 'Team Level {level} Detail',
    Trade: 'Transaction',
    CustomerService: 'Customer Service',
    Rules: 'Introduction',
    BetRecords: 'Game history',
    Promotion: 'Promotion',
    Vip: 'Vip',
    Withdraw: 'Wallets',
    Recharge: 'Wallets',
    TeamMemberDetail: 'Team Member Detail',
    SiteMessages: 'Messages',
    Article: 'Details',
    Game: 'Game',
    LiveChat: 'Customer Service Chat',
    New_about_terms: 'Terms of Service',
    New_about_privacy: 'Privacy Policy',
    Faqs: 'FAQs',
    DailySignIn: 'Daily SignIn',
    Account: 'My Account',
    PromotionBonus: 'Bonus',
    Rewards: 'Bonus',
    Favorite: 'My Favorite',
  },
};
