import createAntApp from './app.js';
import { createApp } from 'vue';
import antd from 'ant-design-vue';

import 'src/styles/OverrideAntD.scss';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons */
import {
  faHome,
  faStar,
  faGift,
  faCalendarDays,
  faUser,
  faUsers,
  faRightToBracket,
  faRightFromBracket,
  faDownload,
  faHeadset,
  faKey,
  faRectangleList,
  faGamepad,
  faWallet,
  faBurst,
  faTable,
  faChessBoard,
  faGlobe,
  faMoneyBill,
  faCircleLeft,
  faVolumeHigh,
  faRotate,
  faSun,
  faCircleQuestion,
  faCheck,
  faCircleDollarToSlot,
  faClipboard,
  faPeopleGroup,
  faEnvelope,
  faGear,
  faChessKnight,
  faAddressCard,
  faMobileScreen,
  faIdBadge,
  faSackDollar,
  faUnlockKeyhole,
  faLock,
  faBitcoinSign,
  faHandHoldingDollar,
  faShieldHalved,
  faCoins,
  faCircleInfo,
  faUserPlus,
  faArrowRight,
  faArrowLeft,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faDiceD20,
  faCalendarCheck,
  faCopy,
  faBars,
  faBarsStaggered,
  faCirclePlay,
  faAngleDown,
  faInfo,
} from '@fortawesome/free-solid-svg-icons';

import { faTelegram } from '@fortawesome/free-brands-svg-icons';

import Carousel3d from 'vue3-carousel-3d';
import 'vue3-carousel-3d/dist/index.css';
// import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';

const icons = [
  faHome,
  faStar,
  faGift,
  faCalendarDays,
  faUser,
  faUsers,
  faRightToBracket,
  faRightFromBracket,
  faDownload,
  faHeadset,
  faKey,
  faRectangleList,
  faGamepad,
  faWallet,
  faBurst,
  faTable,
  faChessBoard,
  faGlobe,
  faMoneyBill,
  faCircleLeft,
  faVolumeHigh,
  faSun,
  faCircleQuestion,
  faCheck,
  faCircleDollarToSlot,
  faClipboard,
  faPeopleGroup,
  faEnvelope,
  faGear,
  faChessKnight,
  faAddressCard,
  faMobileScreen,
  faIdBadge,
  faSackDollar,
  faUnlockKeyhole,
  faLock,
  faBitcoinSign,
  faHandHoldingDollar,
  faShieldHalved,
  faCoins,
  faCircleInfo,
  faRotate,
  faTelegram,
  faUserPlus,
  faArrowRight,
  faArrowLeft,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faDiceD20,
  faCalendarCheck,
  faCopy,
  faBars,
  faBarsStaggered,
  faCirclePlay,
  faAngleDown,
  faInfo,
];
/* add icons to the library */
library.add(icons);

const bootFiles = ['start', 'pinia', 'i18n', 'config', 'iconfont', 'auth', 'end'];
const publicPath = ``;

export function boot(func) {
  return func;
}

async function start({ app, router }, bootFiles) {
  let hasRedirected = false;

  const getRedirectUrl = (url) => {
    try {
      return router.resolve(url).href;
    } catch (err) {}

    return Object(url) === url ? null : url;
  };

  const redirect = (url) => {
    hasRedirected = true;

    if (typeof url === 'string' && /^https?:\/\//.test(url)) {
      window.location.href = url;
      return;
    }

    const href = getRedirectUrl(url);

    // continue if we didn't fail to resolve the url
    if (href !== null) {
      window.location.href = href;
      window.location.reload();
    }
  };

  const urlPath = window.location.href.replace(window.location.origin, '');

  for (let i = 0; hasRedirected === false && i < bootFiles.length; i++) {
    try {
      await bootFiles[i]({
        app,
        router,
        ssrContext: null,
        redirect,
        urlPath,
        publicPath,
      });
    } catch (err) {
      if (err && err.url) {
        redirect(err.url);
        return;
      }

      console.error('[Quasar] boot error:', err);
      return;
    }
  }

  if (hasRedirected === true) {
    return;
  }
  app.use(router);
  app.use(antd);
  app.use(Carousel3d);
  app.component('font-awesome-icon', FontAwesomeIcon);
  app.mount('#ant-app');
}

createAntApp(createApp).then((app) => {
  // eventually remove this when Cordova/Capacitor/Electron support becomes old
  const [method, mapFn] =
    Promise.allSettled !== void 0
      ? [
          'allSettled',
          (bootFiles) =>
            bootFiles.map((result) => {
              if (result.status === 'rejected') {
                console.error('[Quasar] boot error:', result.reason);
                return;
              }
              return result.value.default;
            }),
        ]
      : ['all', (bootFiles) => bootFiles.map((entry) => entry.default)];

  const promiseArr = [];
  for (let i = 0; i < bootFiles.length; i++) {
    promiseArr.push(import('src/boot/' + bootFiles[i]));
  }

  return Promise[method](promiseArr).then((bootFiles) => {
    const boot = mapFn(bootFiles).filter((entry) => typeof entry === 'function');
    start(app, boot);
  });
});
