const routes = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
        meta: { requireLogin: false, requireConfig: true },
        component: () => import('pages/main/Home.vue'),
      },
      {
        path: '/favorite',
        name: 'Favorite',
        meta: { requireLogin: true, requireConfig: true },
        component: () => import('pages/main/Favorite.vue'),
      },
      {
        path: '/promotion',
        name: 'Promotion',
        meta: { requireLogin: true, requireConfig: true },
        component: () => import('pages/main/Promotion.vue'),
      },
      {
        path: '/promotionbonus',
        name: 'PromotionBonus',
        meta: { requireLogin: true, requireConfig: true },
        component: () => import('pages/main/PromotionBonus.vue'),
      },
      {
        path: '/team',
        name: 'Team',
        meta: { requireLogin: true, requireConfig: true },
        component: () => import('src/pages/secondary/Team.vue'),
      },
      {
        path: '/activity',
        name: 'Activity',
        meta: { requireConfig: true },
        component: () => import('pages/main/Activity.vue'),
      },
      {
        path: '/dailySignIn',
        name: 'DailySignIn',
        meta: { requireLogin: true, requireConfig: true },
        component: () => import('src/pages/profile/DailySignIn.vue'),
      },
      {
        path: '/faqs',
        name: 'Faqs',
        meta: { requireConfig: true },
        component: () => import('src/pages/secondary/Faqs.vue'),
      },
      {
        path: 'vip',
        name: 'Vip',
        meta: { requireLogin: true, requireConfig: true },
        component: () => import('src/pages/secondary/Vip.vue'),
      },
      {
        path: 'customerService',
        name: 'CustomerService',
        meta: { requireLogin: false, requireConfig: true },
        component: () => import('src/pages/secondary/CustomerService.vue'),
      },
      {
        path: 'account',
        name: 'Account',
        meta: { requireLogin: true, requireConfig: true },
        component: () => import('src/pages/secondary/Account.vue'),
      },
      {
        path: '/rewards',
        name: 'Rewards',
        meta: { requireLogin: true, requireConfig: true },
        component: () => import('pages/main/Rewards.vue'),
      },
      {
        path: '/rewardsDetail',
        name: 'RewardsDetail',
        meta: { requireLogin: true, requireConfig: true },
        component: () => import('pages/main/RewardsDetail.vue'),
      },
    ],
  },
  {
    path: '/',
    component: () => import('layouts/SecondaryLayoutCustomHeader.vue'),
    children: [
      {
        path: 'game/:gameId',
        name: 'Game',
        meta: { requireLogin: true, requireConfig: true },
        component: () => import('pages/secondary/Game.vue'),
      },
    ],
  },
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: 'siteMessages',
        name: 'SiteMessages',
        meta: { requireLogin: true, requireConfig: true },
        component: () => import('src/pages/secondary/SiteMessage.vue'),
      },
      {
        path: 'customerService',
        name: 'CustomerService',
        meta: { requireLogin: false, requireConfig: true },
        component: () => import('src/pages/secondary/CustomerService.vue'),
      },
      {
        path: 'livechat/:licenseId',
        name: 'LiveChat',
        meta: { requireLogin: false, requireConfig: true },
        component: () => import('src/pages/secondary/LiveChat.vue'),
      },
      {
        path: 'article/:type/:id',
        name: 'Article',
        meta: { requireLogin: false, requireConfig: true },
        component: () => import('src/pages/secondary/Article.vue'),
      },
    ],
  },
  {
    path: '/records',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: 'stream',
        name: 'StreamRecords',
        meta: { requireLogin: true, requireConfig: true },
        component: () => import('src/pages/records/StreamRecord.vue'),
      },
    ],
  },
  {
    path: '/:catchAll(.*)*',
    meta: { requireLogin: false, requireConfig: false },
    component: () => import('pages/Error404.vue'),
  },
];

export default routes;
