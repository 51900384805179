export default {
  type: {
    RECHARGE: 'Recarga',
    WITHDRAW: 'Saque',
    ADMIN_RECHARGE: 'Recarga manual',
    ABNORMAL_COMPENSATION: 'Compensação anormal',
    ADMIN_BONUS: 'Bônus do administrador',
    FIRST_RECHARGE_BONUS: 'Bônus da primeira recarga',
    RECHARGE_BONUS: 'Bônus de recarga',
    WITHDRAW_FAIL: 'Saque falhou',
    WITHDRAW_RETURNED: 'Rejeição da Revisão de Saque',
    TEAM_COMMISSION: 'Comissão da equipe',
    SIGNUP_BONUS: 'Bônus de Assinatura',
    RED_PACKET_BONUS: 'Bônus de pacote vermelho',
    VIP_BONUS: 'Bônus VIP',
    GENERAL_PROMOTION: 'Bônus de promoção',
    SPECIAL_PROMOTION: 'Bônus de promoção',
    WIN: 'Ganho de jogo',
    CANCEL_BET: 'Cancelamento de aposta',
    RETURN_BET_PRESERVE: 'Cancelamento de aposta',
    VIP_REBATE: 'Rebate VIP',
    CREDIT: 'Crédito',
    CANCEL_DEBIT: 'Cancelamento de débito',
    REGISTER_BONUS: 'Bônus de registro',
    BET: 'Aposta',
    SETTLE_BET: 'Aposta',
    BET_PRESERVE: 'Aposta',
    CANCEL_WIN: 'Cancelamento de aposta',
    CANCEL_VIP_REBATE: 'Cancelamento de rebate VIP',
    ABNORMAL_WITHDRAWAL: 'Saque anormal',
    ADMIN_WITHDRAWAL: 'Saque manual',
    DEBIT: 'Débito',
    CANCEL_CREDIT: 'Cancelamento de crédito',
    RECHARGE_REFUND: 'Reembolso de recarga'
  }
}
