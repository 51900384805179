// This is just an example,
// so you can safely delete all default props below

export default {
  nav: {
    home: 'Jogo',
    promotion: 'Promoção',
    records: 'Registro',
    activity: 'Atividade',
    userCenter: 'Meu',
  },
  title: {
    Login: 'Login de usuário',
    Activity: 'Atividade',
    Register: 'Registro de usuário',
    ForgotPassword: 'Esqueceu da senha',
    Home: 'Página inicial',
    UserCenter: 'Centro de usuários',
    Invite: 'Convidar amigos',
    Settings: 'Configurações',
    Avatar: 'Selecione Avatar',
    Password: 'Mudar a senha',
    FundPassword: 'Senha de segurança',
    Bankcard: 'Conta Bancária',
    Messages: 'Mensagens',
    RechargeRecords: 'Histórico do Depósito',
    WithdrawRecords: 'Histórico de Saque',
    StreamRecords: 'Registro de saldo',
    Organization: 'Sobre Nós',
    TermsOfService: 'Termos de Serviço',
    BusinessModel: 'Descrição de Produto',
    CompanyProfile: 'Perfil da Empresa',
    FaqDetail: 'FAQ',
    PixWallet: 'Carteira PIX',
    Team: 'Minha Equipe',
    TeamDetail: 'Detalhes do Nível da Equipe {level}',
    Trade: 'Transação',
    CustomerService: 'Serviço de Cliente',
    Rules: 'Introdução',
    BetRecords: 'História do jogo',
    Promotion: 'Promoção',
    Vip: 'Vip',
    Withdraw: 'Carteiras',
    Recharge: 'Carteiras',
    TeamMemberDetail: 'Detalhe do Membro da Equipe',
    SiteMessages: 'Messagens',
    Article: 'Detalhes',
    Game: 'Jogo',
    LiveChat: 'Bate-papo de atendimento ao cliente',
    RechargeQrcode: 'Pager',
    New_about_terms: 'Termos de Serviço',
    New_about_privacy: 'Política de Privacidade',
    Illustrate: 'Illustrate',
    Faqs: 'FAQs',
    DailySignIn: 'Login Diário',
    Account: 'Minha conta',
    PromotionBonus: 'Bônus',
    Rewards: 'Bônus',
    PromotionDetail: 'Detalhes do usuário de envio direto',
    Favorite: 'Meu favorito',
    RewardsDetail: 'Detalhes do usuário de envio direto',
  },
};
