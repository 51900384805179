import gql from 'graphql-tag'

import { userFields } from './user'

const pixWithdraw = gql`
  mutation pixWithdraw($amount: Int!) {
    pixWithdraw(amount: $amount) {
      ${userFields}
    }
  }
`

export const queries = {}

export const mutations = {
  pixWithdraw
}
