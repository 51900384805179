import { defineStore } from 'pinia';
import { apolloClient } from 'src/apolloClient';
import { Query } from 'src/graphql';
import { notification } from 'ant-design-vue';
import { useI18n } from 'src/boot/i18n';
import EventBus, { GE_LOGOUT } from 'src/layouts/EventBus';
const { t } = useI18n();

export const useUserStore = defineStore('user', {
  state: () => ({
    user: {},
    team: {},
    bankcard: {},
    pixWallet: {},
    wallet: {},
    customerServices: [],
    unreadMessageCount: 0,
    vipBonusStatuses: {},
    signupDays: 0,
    alreadySignupToday: false,
    favoriteGames: [],
    treasurePromotionRewardSummary: [],
    generalPromotionRewards: [],
    teamCommission: [],
    vipRebateBonus: [],
    lossBenefit: { id: 0, amount: 0 },
    contact: {
      acctName: '',
      certId: '',
    },
  }),
  // optional getters
  getters: {
    commission: (state) =>
      state.team?.level1Commission + state.team?.level2Commission + state.team?.level3Commission,
  },
  // optional actions
  actions: {
    async fetchUserInfo() {
      // reset current user data
      this.logout();

      const {
        data: {
          getUserInfo: {
            id,
            name,
            email,
            countryCode,
            mobile,
            avatar,
            vipLevel,
            inviteCode,
            balance,
            withdrawPoint,
            effectiveWithdrawPoint,
            requiredWithdrawPoint,
            requiredWithdrawPointFix,
            totalRechargeAmount,
            totalRechargeOrders,
            totalApplyWithdrawAmount,
            totalApplyWithdrawOrders,
            totalSuccessWithdrawAmount,
            totalSuccessWithdrawOrders,
            vipBonusStatuses,
            signupDays,
            alreadySignupToday,
            team,
            pixWallet,
            customerServices,
            unreadMessageCount,
            totalPromotionBonus,
            totalCommission,
          },
        },
      } = await apolloClient.query({
        query: Query.getUserInfo,
        fetchPolicy: 'network-only',
      });

      if (inviteCode) {
        localStorage.setItem('inviteCode', inviteCode);
      }

      let contactJson = localStorage.getItem(`contact:ant:${id}`);
      let contact;

      if (contactJson) {
        contact = JSON.parse(contactJson);
      } else {
        contact = { acctName: pixWallet?.acctName, certId: pixWallet?.certId };
      }

      this.$patch({
        user: {
          id,
          name,
          email,
          countryCode,
          mobile,
          avatar,
          vipLevel,
          inviteCode,
          balance,
          withdrawPoint,
          effectiveWithdrawPoint,
          requiredWithdrawPoint,
          requiredWithdrawPointFix,
          totalRechargeAmount,
          totalRechargeOrders,
          totalApplyWithdrawAmount,
          totalApplyWithdrawOrders,
          totalSuccessWithdrawAmount,
          totalSuccessWithdrawOrders,
          totalPromotionBonus,
          totalCommission,
        },
        team,
        pixWallet: pixWallet || {},
        customerServices: customerServices || [],
        unreadMessageCount,
        vipBonusStatuses,
        signupDays,
        alreadySignupToday,
        contact,
      });

      this.loadFavoriteGames();
    },

    async updateUserInfo() {
      const {
        data: {
          getUserInfo: {
            id,
            vipLevel,
            balance,
            unreadMessageCount,
            signupDays,
            alreadySignupToday,
            withdrawPoint,
            effectiveWithdrawPoint,
            requiredWithdrawPoint,
            requiredWithdrawPointFix,
            totalRechargeAmount,
            totalRechargeOrders,
            totalApplyWithdrawAmount,
            totalApplyWithdrawOrders,
            totalSuccessWithdrawAmount,
            totalSuccessWithdrawOrders,
            totalCommission,
            team,
          },
        },
      } = await apolloClient.query({
        query: Query.refreshUserInfo,
        fetchPolicy: 'network-only',
      });

      this.$patch({
        user: {
          id,
          vipLevel,
          balance,
          withdrawPoint,
          effectiveWithdrawPoint,
          requiredWithdrawPoint,
          requiredWithdrawPointFix,
          totalRechargeAmount,
          totalRechargeOrders,
          totalApplyWithdrawAmount,
          totalApplyWithdrawOrders,
          totalSuccessWithdrawAmount,
          totalSuccessWithdrawOrders,
          totalCommission,
          team,
        },
        unreadMessageCount,
        signupDays,
        alreadySignupToday,
      });
    },

    updateContactInfo(contact) {
      localStorage.setItem(`contact:ant:${this.user.id}`, JSON.stringify(contact));
      this.$patch({ contact });
    },

    async listTreasurePromotionRewardSummary() {
      let {
        data: { listTreasurePromotionRewardSummary },
      } = await apolloClient.query({
        query: Query.listTreasurePromotionRewardSummary,
        fetchPolicy: 'network-only',
      });

      this.$patch({
        treasurePromotionRewardSummary: listTreasurePromotionRewardSummary,
      });
    },

    async listGeneralPromotionDailyRewards() {
      let {
        data: { listGeneralPromotionDailyRewards },
      } = await apolloClient.query({
        query: Query.listGeneralPromotionDailyRewards,
        fetchPolicy: 'network-only',
      });

      this.$patch({
        generalPromotionRewards: listGeneralPromotionDailyRewards,
      });
    },

    async fetchTeamCommission() {
      let {
        data: { fetchTeamCommission },
      } = await apolloClient.query({
        query: Query.fetchTeamCommission,
        fetchPolicy: 'network-only',
      });

      this.$patch({
        teamCommission: fetchTeamCommission,
      });
    },

    async fetchVipRebateBonus() {
      let {
        data: { fetchVipRebateBonus },
      } = await apolloClient.query({
        query: Query.fetchVipRebateBonus,
        fetchPolicy: 'network-only',
      });

      this.$patch({
        vipRebateBonus: fetchVipRebateBonus,
      });
    },

    async fetchLossBenefit() {
      let {
        data: { fetchLossBenefit },
      } = await apolloClient.query({
        query: Query.fetchLossBenefit,
        fetchPolicy: 'network-only',
      });

      this.$patch({
        lossBenefit: fetchLossBenefit,
      });
    },

    setUser(user) {
      this.$patch({ user });
    },

    setTeam(team) {
      this.$patch({ team });
    },

    setBankcard(bankcard) {
      this.bankcard = bankcard;
    },

    setWallet(wallet) {
      this.wallet = wallet;
    },

    setPixWallet(pixWallet) {
      this.pixWallet = pixWallet;
    },

    setUnreadMessageCount(unreadMessageCount) {
      this.unreadMessageCount = unreadMessageCount;
    },

    updateSignupDays({ signupDays, alreadySignupToday, balance }) {
      this.alreadySignupToday = alreadySignupToday;
      this.signupDays = signupDays;
      this.balance = balance;
    },

    setVipBonusStatuses(vipBonusStatuses) {
      this.vipBonusStatuses = vipBonusStatuses;
    },

    updateBalance(balance) {
      this.user.balance = balance;
    },

    updateAvatar(avatar) {
      this.user.avatar = avatar;
    },

    loadFavoriteGames() {
      const favoriteGamesJson = localStorage.getItem(`favoriteGames:${this.user.id}`) || '[]';
      const favoriteGames = JSON.parse(favoriteGamesJson);
      this.favoriteGames = favoriteGames;
    },

    addToFavorite(game) {
      const exists = this.favoriteGames.find((element) => element.id === game.id);

      if (!exists) {
        const favoriteGame = Object.assign(
          {},
          { ...game, sortValue: this.favoriteGames.length + 1 }
        );

        const favoriteGames = this.favoriteGames.slice();

        favoriteGames.push(favoriteGame);

        this.favoriteGames = favoriteGames;

        const json = JSON.stringify(this.favoriteGames);

        localStorage.setItem(`favoriteGames:${this.user.id}`, json);

        notification.success({
          message: '',
          description: t('common.addFavoriteSuccss'),
          placement: 'top',
        });
      }
    },

    removeFromFavorite(game) {
      const index = this.favoriteGames.findIndex((element) => element.id === game.id);

      if (index >= 0) {
        const favoriteGames = this.favoriteGames.slice();
        favoriteGames.splice(index, 1);

        this.favoriteGames = favoriteGames;

        const json = JSON.stringify(this.favoriteGames);
        localStorage.setItem(`favoriteGames:${this.user.id}`, json);
      }
    },

    logout() {
      this.user = {};
      this.team = {};
      this.data = {};
      this.bankcard = {};
      this.wallet = {};
      this.pixWallet = {};
      this.customerServices = [];
      this.unreadMessageCount = 0;
      this.favoriteGames = [];

      EventBus.emit(GE_LOGOUT);
    },
  },
});
