import copy from 'copy-to-clipboard';
// import NoticeDialog from 'src/components/NoticeDialog.vue';
// import DailySignupDialog from 'src/components/DailySignupDialog.vue';
// import DownloadIosDialog from 'src/components/DownloadDialog.vue';
import { useI18n } from 'src/boot/i18n';
import { sendIdentifyCode as apiSendIdentifyCode } from 'src/api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/pt-br';
import { notification, Modal } from 'ant-design-vue';
import EventBus from 'src/layouts/EventBus';
import { GE_SHOW_WALLET } from 'src/layouts/EventBus';
import { useAuthStore } from 'src/store/authStore';
import { useConfigStore } from 'src/store/configStore';
import { useUserStore } from 'src/store/userStore';

import router from 'src/router';
import { RE_MOBILE_NUMBER_MASK, TIMEZONE } from './constants';
import { ref, h } from 'vue';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('pt-br');

const { t } = useI18n();

export function copyText(val) {
  const res = copy(val, { format: 'text/plain' });

  if (res) {
    notification.success({
      message: t('common.success'),
      description: t('common.copysuccess'),
      placement: 'top',
      style: 'color: green',
    });
  }
}

export const sendIdentifyCode = async (countryCode, mobile, email, sendingRef, cooldownRef) => {
  try {
    sendingRef.value = true;

    const res = await apiSendIdentifyCode(countryCode, mobile, email);

    if (res.cooldown && res.cooldown > 0) {
      cooldownRef.value = res.cooldown;
      const cooldownRefTimer = setInterval(() => {
        cooldownRef.value--;
        if (cooldownRef.value <= 0) {
          clearInterval(cooldownRefTimer);
        }
      }, 1000);
    }

    if (res.success) {
      notification.info({
        message: '',
        description: t('account.message.smsSent'),
        placement: 'top',
        style: 'color: green',
      });
    } else {
      notification.warning({
        message: '',
        description: t(res.message || 'error.server.unknown'),
        placement: 'top',
        style: 'color: green',
      });
    }
  } catch (e) {
    console.error(e);
    notification.error({
      message: '',
      description: t('account.message.smsFailed'),
      placement: 'top',
      style: 'color: green',
    });
  } finally {
    sendingRef.value = false;
  }
};

export const showMessageDialog = ({ title, content }) => {
  Modal.info({
    title,
    content: h('div', {}, [h('p', content)]),
    onOk() {},
  });
};

export const showDailySignupDialog = () => {
  // Dialog.create({
  //   component: DailySignupDialog,
  // });
};

export const gotoRecharge = () => {
  EventBus.emit(GE_SHOW_WALLET, { showWithDraw: false });
};

export const throttle = (cb, delay = 250) => {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      cb(...args);
    }, delay);
  };
};

export const openURL = (url) => {
  window.open(url);
};

export const gotoCarouselLink = (item) => {
  switch (item.linkType?.toUpperCase()) {
    case 'INTERNAL':
      if (item.link === '/deposit') {
        EventBus.emit(GE_SHOW_WALLET, { showWithDraw: false });
      } else if (item.link === '/widthdraw') {
        EventBus.emit(GE_SHOW_WALLET, { showWithDraw: true });
      } else {
        item.link ? router.push(item.link) : console.error('carousel item link not set');
      }
      break;

    case 'EXTERNAL':
      item.link ? openURL(item.link) : console.log('carousel external link not set');
      break;

    case 'ARTICLE':
      item.content
        ? router.push(`/article/carousel/${item.id}`)
        : console.error('carousel item link not set');
      break;

    default:
      break;
  }
};

export const convertServerDateTime = (val) => {
  if (val) {
    const a = val.split(/[^0-9]/);
    const m = parseInt(a[1]) - 1;
    return Date.UTC(+a[0], m, +a[2], +a[3], +a[4], +a[5]);
  }

  return null;
};

const dateFormatter = new Intl.DateTimeFormat('zh-Hans-CN', {
  timeZone: TIMEZONE,
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  // @ts-ignore
  hourCycle: 'h23',
});

export function formatServerDateTime(val) {
  if (val) {
    const utcDate = convertServerDateTime(val);
    return dateFormatter.format(utcDate).replaceAll('/', '-');
  } else {
    return '';
  }
}

export const convertTransactionId = (transactionId) => {
  if (typeof transactionId === 'string') {
    return transactionId.replace(/^(\w{8})\w+(\w{16}$)/, '$1...$2');
  } else {
    return null;
  }
};

export const maskEmail = (email) => {
  if (typeof email === 'string') {
    return email.replace(/^([^@]+)@([^\.]+)\.(.*)/, '$1@***.$3');
  } else {
    return null;
  }
};

export function maskMobile(val) {
  if (val) {
    return val.replace(RE_MOBILE_NUMBER_MASK, '$1****$2');
  }

  return '';
}

export function postForm(path, params) {
  // The rest of this code assumes you are not using a library.
  // It can be made less wordy if you use one.
  const form = document.createElement('form');
  form.method = 'post';
  form.action = path;

  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
}

export function formatTimestamp(val, format = 'YYYY-MM-DD HH:mm:ss') {
  if (val) {
    return dayjs(val).format(format);
  }
}

export function getOS() {
  const userAgent = window.navigator.userAgent;
  let os = null;

  if (/wv\)/.test(userAgent)) {
    os = 'app';
  } else if (/(iPhone|iPad|iPod|iOS)/i.test(userAgent)) {
    os = 'ios';
  } else if (/(Android)/i.test(navigator.userAgent)) {
    os = 'android';
  } else {
    os = 'pc';
  }

  return os;
}

export const showIosDownloadDialog = ref(false);
export function downloadApp(configStore) {
  switch (getOS()) {
    case 'android':
    case 'pc':
      openURL(configStore.sysConfig.downloadUrl);
      break;

    case 'ios':
      showIosDownloadDialog.value = true;
      break;

    default:
      break;
  }
}

export function gotoCustomService() {
  const authStore = useAuthStore();
  const cfgStore = useConfigStore();
  const userStore = useUserStore();

  const links = authStore.accessToken ? userStore.customerServices : cfgStore.customerServices;
  if (links.length > 1) {
    router.push({ path: 'customerService' });
  } else {
    const item = links[0];
    openCustomService(item);
  }
}

export function openCustomService(item) {
  switch (item.linkType?.toUpperCase()) {
    case 'TELEGRAM':
      item.link
        ? openURL(`https://t.me/${item.link}`)
        : console.error('customer service link not set');
      break;
    case 'WHATSAPP':
      item.link
        ? openURL(`https://wa.me/${item.link}`)
        : console.error('customer service link not set');
      break;
    case 'LIVECHAT':
      item.link
        ? router.push(`/livechat/${item.link}`)
        : console.error('customer service link not set');
      break;
    case 'EXTERNAL':
      item.link ? openURL(item.link) : console.error('customer service link not set');
      break;
    default:
      break;
  }
}
