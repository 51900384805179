import { CURRENCY_NAME } from 'src/utils/constants';

export default {
  ok: 'Ok',
  cancel: 'Cancelar',
  go: 'Ir',
  buy: 'Comprar',
  hint: 'Dica',
  prompt: 'Rápido',
  copy: 'Copiar',
  paste: 'Colar',
  bonus: 'Bônus',
  receive: 'Receber',
  receiveSuccess: 'Receba sucesso',
  checkIn: 'Check-in',
  checkInSuccess: 'Check-in sucesso',
  dayN: 'Dia {n}',
  recharge: 'Depósito',
  RECHARGE: 'DEPÓSITO',
  withdraw: 'Sacar',
  illustrate: 'Ilustrar',
  WITHDRAW: 'RETIRADA',
  totalRecharge: 'Depósito total',
  firstRecharge: 'Primeiro depósito',
  totalWithdraw: 'Saque total',
  wallet: 'Carteira',
  accountBalance: 'Saldo da conta',
  goHome: 'Ir para página incial',
  continue: 'Continuar',
  myFavorites: 'Meus favoritos',
  retry: 'Tentar de novo',
  submit: 'Submetir',
  logout: 'Logout',
  avatar: 'Avatar',
  password: 'Senha',
  contact: 'Contato',
  more: 'Mais',
  records: 'Registros',
  noData: 'Sem dados',
  invite: 'Convidar',
  invited: 'Convidado',
  inviteCode: 'Código de convite',
  estimated: 'Estimativa',
  version: 'Versão',
  email: 'E-mail',
  mobile: 'Celular',
  linkToShare: 'Link para compartilhar',
  copyLink: 'Copiar link',
  cashFlow: 'Fluxo de caixa',
  team: 'Equipe',
  myTeam: 'Minha equipe',
  depositors: 'Depositantes',
  settings: 'Configurações',
  messages: 'Mensagens',
  aboutUs: 'Sobre nós',
  download: 'Baixar aplicativo',
  rule: 'Regra',
  selected: 'Selecionado',
  action: 'Ação',
  profits: 'Ganhos',
  orderNo: 'Ordem No',
  min: 'Min',
  max: 'Max',
  fee: 'Tarifa',
  amount: 'Montante',
  level: 'Nível {n}',
  commission: 'Comissão',
  totalCommission: 'Comissão Total',
  regDate: 'Junte-se à',
  registration: 'Registro',
  members: 'Membros',
  validBets: 'Apostas válidas',
  balance: 'Saldo',
  membercenter: 'Centro de membros',
  invalidNumber: 'Insira números',
  detail: 'Detalhes',
  all: 'Tudo',
  today: 'Hoje',
  yesterday: 'Ontem',
  week: 'Semana passada',
  income: 'Renda',
  expense: 'Despesas',
  beginDate: 'Data de início',
  endDate: 'Data de término',
  chooseDate: 'Data de escolha',
  game: 'Jogo',
  betAmount: 'Aposta',
  winAmount: 'Ganhar',
  clickToSeeMore: 'Entre para ver detalhes',
  copysuccess: 'sucesso da cópia',
  currencyAmount: ({ named }) => `${CURRENCY_NAME} ${named('amount') / 100}`,
  percentage: ({ named }) => `${named('value') / 100} %`,
  ENTER: 'ENTRAR',
  REGISTER: 'REGISTRAR',
  home: 'Jogos',
  favorite: 'Favorito',
  promotion: 'Promoção',
  activity: 'Atividade',
  user: 'Usuário',
  userCenter: 'Centro do usuário',
  customerService: 'Atendimento ao cliente',
  changePassword: 'Alterar a senha',
  streamRecords: 'Registros de saldo da conta',
  betRecords: 'Jogo',
  signout: 'Logout',
  received: 'Recebido',
  rechargetRecords: 'Recarga',
  inputRechargeNum: 'Insira o valor:',
  unread: 'Não lida',
  readed: 'Tenho lido',
  modifyPix: 'CONFIGURAÇÕES >',
  configuration: 'CONFIGURAÇÕES',
  date: 'Data',
  daily: 'Diariamente',
  enter: 'Insira',
  cassino: 'Cassino',
  PLAYROLL: 'JOGAR ROLLBIT',
  PROMOTION: 'PROMOÇÕES',
  MEMBERREWARDS: 'RECOMPENSAS DE MEMBRO',
  OTHERS: 'OUTRA',
  helpCenter: 'Centro de Ajuda',
  FAQ: 'FAQ',
  vip: 'Vip',
  vipLvx: ({ named }) => `Vip Lv.${named('level')}`,
  curviplv: 'Nível vip atual:',
  success: 'Sucesso',
  levelupcondition: 'Condições de atualização',
  myaccount: 'Minha conta',
  home_select_provider: 'Escolher Fornecedores',
  home_tab_collect: 'Coletar',
  game_manufactures: 'Fabricantes de jogos',
  operate: 'Nome',
  time: 'Tempo',
  funds: 'Fundos',
  vipBonusRewarded: 'Recompensado',
  Name: 'Nome',
  total: 'Todo',
  person: 'pessoas',
  alreceive: 'Já recebi',
  addFavoriteSuccss: 'Coleta bem-sucedida',
  status: 'Estado',
  noBonus: 'Nenhuma recompensa disponível ainda',
};
