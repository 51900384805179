import gql from 'graphql-tag';
import { pagination } from './common';

const message = `{
  id
  type
  params
  title
  content
  read
  insertedAt
}`;

const listSiteMessages = gql`
  query listSiteMessages($paginationParams: PaginationParams) {
    listSiteMessages(paginationParams: $paginationParams)
    @connection(key: "listSiteMessages")
    ${pagination(message)}
  }
`;

const streamRecord = `{
  id
  orderNo
  prefix
  type
  balance
  amount
  balanceAfter
  insertedAt
}`;

const listStreamRecords = gql`
  query listStreamRecords($paginationParams: PaginationParams!, $listType: StreamListType!, $beginDate: Date, $endDate: Date) {
    listStreamRecords(paginationParams: $paginationParams, listType: $listType, beginDate: $beginDate, endDate: $endDate)
    @connection(key: "listStreamRecords", filter: ["listType", "beginDate", "endDate"])
    ${pagination(streamRecord)}
  }`;

const rechargeOrder = `{
    id
    orderNo
    transactionId
    amount
    status
    images
    insertedAt
  }
`;

const listRechargeOrders = gql`
  query listRechargeOrders($paginationParams: PaginationParams!, $beginDate: Date, $endDate: Date) {
    listRechargeOrders(paginationParams: $paginationParams, beginDate: $beginDate, endDate: $endDate)
      @connection(key: "listRechargeOrders", filter: ["beginDate", "endDate"])
      ${pagination(rechargeOrder)}
  }
`;

const withdrawOrder = `{
    id
    orderNo
    amount
    tax
    status
    insertedAt
  }
`;

const listWithdrawOrders = gql`
  query listWithdrawOrders($paginationParams: PaginationParams!, $beginDate: Date, $endDate: Date) {
    listWithdrawOrders(paginationParams: $paginationParams, beginDate: $beginDate, endDate: $endDate)
    @connection(key: "listWithdrawOrders", filter: ["beginDate", "endDate"])
    ${pagination(withdrawOrder)}
  }
`;

const userTeam = `{
  id
  name
  avatar
  email
  mobile
  regDate
  withdrawPoint
  firstRechargeAmount
  totalRechargeAmount
  totalBetAmount
  totalSuccessWithdrawAmount
}
`;

const listUserTeam = gql`
query listUserTeam($paginationParams: PaginationParams!, $level: Int) {
  listUserTeam(paginationParams: $paginationParams, level: $level)
  @connection(key: "listUserTeam", filter: ["level"])
  ${pagination(userTeam)}
}
`;

const betRecord = `{
  id
  orderNo
  game
  status
  result
  betAmount
  winAmount
  insertedAt
}`;

const listBetRecords = gql`
  query listBetRecords($paginationParams: PaginationParams!, $providerId: Int, $date: Date) {
    listBetRecords(paginationParams: $paginationParams, providerId: $providerId, date: $date)
    @connection(key: "listBetRecords", filter: ["providerId", "date"])
    ${pagination(betRecord)}
  }`;

const listTabTop10Games = gql`
  query listTabTop10Games($tabId: Int!) {
    listTabTop10Games(tabId: $tabId) {
      id
      name
      logo
      providerId
      sortValue
      minBalance
      screenOrientation
    }
  }
`;

const listTop10Players = gql`
  query listTop10Players {
    listTop10Players {
      id
      game {
        id
        name
        logo
        providerId
        sortValue
        minBalance
        screenOrientation
      }
      userId
      value
    }
  }
`;

const listInviteRecords = gql`
  query listInviteRecords($level: Int!) {
    listInviteRecords(level: $level) {
      id
      date
      invitedUsers
      firstRechargeUsers
      generalPromotionBonus
      specialPromotionBonus
      promotionBonus
      commissionAmount
    }
  }
`;

export const queries = {
  listSiteMessages,
  listStreamRecords,
  listRechargeOrders,
  listWithdrawOrders,
  listUserTeam,
  listBetRecords,
  listTabTop10Games,
  listTop10Players,
  listInviteRecords,
};
