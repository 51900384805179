export default {
  vipName: 'VIP{level}',
  slogan: 'Not far from VIP{level}',
  depositAmount: 'Deposit Amount',
  currentDepositAmount: 'Current Deposit Amount',
  codificationVolume: 'Codification Volume',
  currentCodificationVolume: 'Codification Volume',
  numberWithdrawalPrefix: 'Number of withdrawal:',
  numberWithdrawal: '{number} times/day',
  maxWithdrawalAmountPrefix: 'Maximum withdrawal amount:',
  maxWithdrawalAmount: '@:common.currencyAmount',
  withdrawalFeePrefix: 'Withdrawal fee:',
  withdrawalFee: '@:common.percentage',
  claimReward: 'Claim Reward',
  rewardClaimed: 'Reward Claimed',
  title: {
    allVips: 'All VIPs',
    vipLevelReward: 'VIP Level Reward',
    levelPrivileges: 'Level privileges',
    updateCondition: 'Update Condition',
    guaranteedTopUp: 'VIP guaranteed top-up',
    almostDaysToRecharge: 'Almost days to recharge BRL',
    levelSignupReward: 'VIP {level} Check-in Reward',
    signupReward: ' Check-in Reward',
  },
};
