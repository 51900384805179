export default {
  server: {
    invalidAccountStatus: 'Invalid account status',
    invalidMobileNumber: 'Invalid mobile phone number',
    invalidSmsPhoneNumber: "Can't send sms to specified mobile phone",
    accountNameRequired: 'Account Name is required',
    accountNotExists: 'Account does not exist',
    cert_id_exists: 'Certification ID already exists',
    invalid_cert_id_format: 'Invalid certification ID format',
    accountExists: 'User account already exists',
    registerIpRestrict: 'Registration is forbidden',
    invalidPassword: 'Wrong password',
    sendSmsLimited: 'SMS exceed system limitation, please try later',
    sendSmsFailed: 'SMS send failed',
    smsChannelDown: 'SMS channel temporarily down',
    identifyCodeNotSent: 'Invalid verification code',
    identifyCodeNotMatch: 'Wrong verification code',
    invalidInviteCode: 'Wrong invite code',
    cooldown: 'Please wait until cooldown timer expired',
    wrong_password: 'Wrong password',
    identify_code_not_match: 'Wrong verification code',
    not_enough_balance: 'Not enough balance',
    withdraw_locked: 'Not enough codification volume',
    insufficient_withdraw_point:
      'It is also necessary to complete the qualifying bet volume @:common.currencyAmount',
    min_withdraw_amount: 'Withdrawal amount is not allowed',
    vip_withdrawal_limit: 'The maximum number of withdrawals today has been reached',
    invalid_withdraw_amount: 'Withdrawal amount is not allowed',
    invalid_bet_amount: 'Invalid bet amount',
    channel_server_error: 'Payment channel temporarily unavailable',
    http_request_error: 'Payment channel temporarily unavailable',
    channel_timeout: 'Payment channel temporarily unavailable',
    recharge_time_limit: 'Recharge is not allowed at this time, please try later',
    invalid_recharge_amount: 'Transfer amount less than required is now allowed',
    wallet_balance_0: 'Insufficient wallet balance, please keep at least 0.01 USDT in your wallet',
    already_signup: 'You have signed in today',
    vip_bonus_received: 'VIP rewards have been claimed',
    game_in_maintenance: 'Game is under maintenance',
    restricted_country: 'Restricted country',
    permission_denied: 'Permission denied',
    unknown: 'Something went wrong',
  },
  network: {
    400: 'Bad request',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Resource not exists',
    405: 'Network error',
    408: 'Request timeout',
    500: 'Server internal error',
    501: 'Server internal error',
    502: 'Server network error',
    503: 'Server temporarily unavailable',
    504: 'Network timeout',
    505: 'Browser error',
    unknown: 'Load data failed due to network error',
  },
  hint: {
    not_enough_balance:
      'You do not have enough balance to complete the operation, you can go to the recharge page to recharge your account.',
    network:
      'Sorry，something went wrong communicating with the server, please check your network and retry later.',
    404: 'Oops, nothing found here...',
  },
};
