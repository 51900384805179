import { CURRENCY_NAME } from 'src/utils/constants';

export default {
  ok: 'Ok',
  cancel: 'Cancel',
  go: 'Go',
  buy: 'Buy',
  hint: 'Hint',
  copy: 'copy',
  paste: 'paste',
  bonus: 'bonus',
  checkIn: 'Check In',
  checkInSuccess: 'Sign in successfully',
  dayN: 'day {n}',
  recharge: 'Deposit',
  illustrate: 'Illustrate',
  withdraw: 'Withdraw',
  totalRecharge: 'Total deposit',
  firstRecharge: 'First deposit',
  totalWithdraw: 'Total Withdrawal',
  wallet: 'wallet',
  accountBalance: 'Account balance',
  goHome: 'Go Home',
  continue: 'Continue',
  myFavorites: 'My Favorites',
  retry: 'Retry',
  submit: 'Submit',
  logout: 'Logout',
  avatar: 'Avatar',
  password: 'Password',
  contact: 'Contact',
  more: 'More',
  records: 'Records',
  noData: 'No Data',
  inviteCode: 'Invitation Code',
  version: 'Version',
  email: 'Email',
  mobile: 'Mobile Phone',
  linkToShare: 'Link to share',
  copyLink: 'Copy Link',
  cashFlow: 'cash flow',
  team: 'Team',
  myTeam: 'my team',
  depositors: 'Depositors',
  settings: 'settings',
  messages: 'messages',
  aboutUs: 'about us',
  download: 'Download App',
  rule: 'Rule',
  selected: 'Selected',
  action: 'Action',
  profits: 'Profits',
  orderNo: 'Order No',
  min: 'Min',
  max: 'Max',
  fee: 'Fee',
  amount: 'Amount',
  level: 'Level {n}',
  commission: 'Commission',
  totalCommission: 'Total Commission',
  regDate: 'Join At',
  members: 'Members',
  validBets: 'Valid Bets',
  balance: 'Balance',
  membercenter: 'Member Center',
  invalidNumber: 'Please input numbers',
  detail: 'Detail',
  all: 'All',
  today: 'Today',
  yesterday: 'Yesterday',
  week: 'Week',
  income: 'Income',
  expense: 'Expense',
  beginDate: 'Start Date',
  endDate: 'End Date',
  chooseDate: 'Choose Date',
  game: 'Game',
  betAmount: 'Bet',
  winAmount: 'Win',
  clickToSeeMore: 'Enter to view details',
  copysuccess: 'copy success',
  currencyAmount: ({ named }) => `${CURRENCY_NAME} ${named('amount') / 100}`,
  percentage: ({ named }) => `${named('value') / 100} %`,
  home: 'Game',
  ENTER: 'ENTER',
  REGISTER: 'REGISTER',
  favorite: 'Favorito',
  promotion: 'Promotion',
  activity: 'Activity',
  user: 'User',
  userCenter: 'User center',
  customerService: 'Customer service',
  changePassword: 'Change password',
  streamRecords: 'Stream records',
  betRecords: 'Bet records',
  signout: 'Logout',
  receive: 'Receive',
  receiveSuccess: 'Receive sucess',
  received: 'Received',
  rechargetRecords: 'Recharge records',
  inputRechargeNum: 'Enter the amount:',
  unread: 'UnRead',
  readed: 'Readed',
  modifyPix: 'Modify',
  configuration: 'Configuration',
  date: 'Date',
  daily: 'Dialy',
  enter: 'Enter',
  cassino: 'Cassino',
  PLAYROLL: 'PLAY ROLLBIT',
  PROMOTION: 'PROMOTION',
  MEMBERREWARDS: 'MEMBER REWARDS',
  OTHERS: 'OTHERS',
  helpCenter: 'Help Center',
  FAQ: 'FAQ',
  vip: 'Vip',
  vipLvx: ({ named }) => `Vip ${named['level']}`,
  curviplv: 'Current vip level:',
  success: 'Success',
  levelupcondition: 'Upgrade conditions',
  myaccount: 'My account',
  game_manufactures: 'Game manufacturers',
  operate: 'Operate',
  time: 'Time',
  funds: 'Funds',
  vipBonusRewarded: 'Rewarded',
  total: 'Total',
  person: 'person',
  alreceive: 'Already received it',
  addFavoriteSuccss: 'Collection successful',
  noBonus: 'No rewards available',
};
