import common from './common'
import message from './message'
import routes from './routes'
import home from './home'
import account from './account'
import site from './site'
import siteMessage from './siteMessage'
import recharge from './recharge'
import withdraw from './withdraw'
import profile from './profile'
import stream from './stream'
import service from './service'
import provider from './provider'
import vip from './vip'
import error from './error'

export default {
  common,
  message,
  routes,
  home,
  siteMessage,
  account,
  recharge,
  withdraw,
  stream,
  profile,
  provider,
  vip,
  site,
  service,
  error
}
