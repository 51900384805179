<template>
  <router-view />
</template>

<script setup>
import { computed, provide } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'src/boot/i18n'
import { apolloClient } from 'src/apolloClient'
import { DefaultApolloClient } from '@vue/apollo-composable'

const { currentRoute } = useRouter()
const { t } = useI18n()

document.body.classList.add('portrait')

const title = computed(() => t(`routes.title.${currentRoute.value.name}`, currentRoute.value.params))
provide(DefaultApolloClient, apolloClient)
</script>

<style lang="scss" scoped>
@import 'src/styles/App';
</style>
