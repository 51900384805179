export default {
  label: {
    avatar: 'Avatar',
    nickname: 'Apelido',
    mobile: 'Número de celular',
    email: 'E-mail',
    bankcard: 'Conta bancária',
    password: 'Senha de login',
    fundPassword: 'Senha de segurança',
    changeAvatar: 'Mudar agora',
    selectAvatar: 'Selecione o seu avatar',
    setPassword: 'Defina a senha',
    currentPassword: 'Senha atual',
    newPassword: 'Nova senha',
    passwordAgain: 'Nova senha de novo',
    changePassword: 'Mudar a senha',
    selectLanguage: 'Selecione o idioma',
    language: 'linguagem',
    forgotPassword: 'Esqueceu da senha',
    contactPhone: 'Número de celular de contato',
    contactEmail: 'Endereço de e-mail de contato',
    account: 'Sobre configurações',
    streamRecords: 'Registros de saldo da conta',
    rechargeRecords: 'Registros de recarga',
    withdrawRecords: 'Registros de saque',
    inviteFriends: 'Convidar amigos',
    aboutUs: 'Sobre Nós',
    appDownload: 'Download do aplicativo',
    pixWallet: 'Carteira de PIX',
    pixAcctType: 'Tipo de conta de PIX',
    pixAcctName: 'Nome de conta de PIX',
    pixAcctNo: 'Número de conta de PIX',
    cpfCertId: 'CPF',
    dailySignIn: 'Login Diário',
  },
  message: {
    avatarChanged: 'Avatar mudado',
    fundPasswordUpdated: 'Senha de segurança atualizada',
    passwordChanged: 'Senha mudada',
    bankcardUpdated: 'Conta bancária atualizada',
    pixWalletUpdated: 'Conta de carteira de PIX atualizada',
  },
  placeholder: {
    currentPassword: 'Senha atual',
    newPassword: 'Nova senha',
    passwordAgain: 'Nova senha de novo',
    contactPhone: 'Digite o número de celular',
    contactEmail: 'Endereço de e-mail de contato',
    pixAcctType: 'Selecione o tipo de conta de PIX',
    pixAcctName: 'Digite o nome de conta de PIX',
    pixAcctNo: 'Digite o número de conta de PIX',
    cpfCertId: 'Digite o código fiscal do beneficiário',
  },
  hint: {
    currentPassword: 'Senha de login atual',
    newPassword: 'Defina a nova senha',
    confirmPassword: 'Confirme a nova senha',
  },
  tips: {
    forgotPassword: 'Caso você esqueça a senha de login atual, vá para {0}.',
  },
};
