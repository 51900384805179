import { defineStore } from 'pinia'
import { login as apiLogin, refreshAccessToken } from 'src/api'
import { useUserStore } from 'src/store/userStore'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    refreshToken: null,
    accessToken: null
  }),
  // optional getters
  getters: {},
  // optional actions
  actions: {
    async init() {
      this.refreshToken = localStorage.getItem('refreshToken')
      this.accessToken = localStorage.getItem('accessToken')
    },

    async refreshAccessToken() {
      try {
        if (this.refreshToken) {
          const { success, accessToken, message } = await refreshAccessToken(this.refreshToken)

          if (success) {
            this.accessToken = accessToken
            localStorage.setItem('accessToken', accessToken)

            return accessToken
          } else {
            throw message
          }
        }
      } catch (e) {
        console.error(e)
        // clear refresh token & access token
        this.logout()
        return null
      }
    },

    async login(countryCode, accountName, password) {
      try {
        const { success, message, accessToken, refreshToken } = await apiLogin(countryCode, accountName, password)

        if (success) {
          this.refreshToken = refreshToken
          this.accessToken = accessToken
          const userStore = useUserStore()
          localStorage.setItem(`favoriteGames:${userStore.user.id}`, '[]')
          localStorage.setItem('refreshToken', refreshToken)
          localStorage.setItem('accessToken', accessToken)
        }

        return { success, message }
      } catch (e) {
        console.error(e)
        return { success: false, message: 'error.exception' }
      }
    },

    logout() {
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('accessToken')

      this.refreshToken = null
      this.accessToken = null
    }
  }
})
