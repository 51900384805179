import gql from 'graphql-tag';

const isTransactionIdExists = gql`
  query isTransactionIdExists($txid: String) {
    isTransactionIdExists(txid: $txid)
  }
`;

const isPixCertIdExists = gql`
  query isPixCertIdExists($certId: String!) {
    isPixCertIdExists(certId: $certId)
  }
`;

const listRechargeBonuses = gql`
  query listRechargeBonuses {
    listRechargeBonuses
  }
`;

const calcRechargeBonus = gql`
  query calcRechargeBonus($amount: Int) {
    calcRechargeBonus(amount: $amount)
  }
`;

const usdtRecharge = gql`
  mutation usdtRecharge($params: UsdtRechargeParams!) {
    usdtRecharge(params: $params) {
      id
      orderNo
    }
  }
`;

const bankRecharge = gql`
  mutation bankRecharge($params: BankRechargeParams!) {
    bankRecharge(params: $params) {
      id
      orderNo
    }
  }
`;

const channelRecharge = gql`
  mutation channelRecharge(
    $amount: Int!
    $channelId: Int
    $returnUrl: String!
    $acceptRechargeBonus: Boolean!
    $acctName: String
    $certId: String
  ) {
    channelRecharge(
      amount: $amount
      channelId: $channelId
      returnUrl: $returnUrl
      acceptRechargeBonus: $acceptRechargeBonus
      acctName: $acctName
      certId: $certId
    )
  }
`;

export const queries = {
  isTransactionIdExists,
  isPixCertIdExists,
  listRechargeBonuses,
  calcRechargeBonus,
};

export const mutations = {
  usdtRecharge,
  bankRecharge,
  channelRecharge,
};
