export default {
  vipName: 'VIP{level}',
  slogan: 'Não muito longe de VIP{level}',
  depositAmount: 'Montante do depósito',
  currentDepositAmount: 'Montante do Depósito Atual',
  codificationVolume: 'Volume de Codificação',
  currentCodificationVolume: 'Volume de Codificação',
  numberWithdrawlPrefix: 'Número de saque',
  numberWithdrawal: '{number} vezes/dia',
  maxWithdrawalAmountPrefix: 'Montante máximo de retirada',
  maxWithdrawalAmount: '@:common.currencyAmount',
  withdrawalFeePrefix: 'Taxa de retirada',
  withdrawalFee: '@:common.percentage',
  claimReward: 'Reivindicar Recompensa',
  rewardClaimed: 'Recompensa Reivindicada',
  rebateRatePrefix: 'Esconto de apostas',
  rebateRate: '@:common.percentage',
  title: {
    allVips: 'Todos os VIPs',
    vipLevelReward: 'Recompensa do Nível de VIP',
    levelPrivileges: 'Privilégios de Nível',
    updateCondition: 'Atualizar Condição',
    guaranteedTopUp: 'Recarga VIP garantida ',
    almostDaysToRecharge: 'Quase dias para recarregar BRL',
    levelSignupReward: 'Prémio de Check-in do VIP {level}',
    signupReward: ' Prémio de Check-in',
  },
};
