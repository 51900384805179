export default {
  title: {
    promotion: 'Promotion',
    illustration: 'Illustration'
  },
  label: {
    inviteDescription: 'This site offers games with an adventure experience.',
    onlineCustomerService: 'Online customer service',
    helpCenter: 'Help center',
    allGames: 'All'
  },
  message: {}
}
